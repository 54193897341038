.about-container {

  align-items: 'center';
  width: '100%';
}

/* .about-box {
  background: 'black';
  filter: "drop-shadow(0 0 1rem #ffff0020)";
  border-radius: "1rem";
  width: '580px'; 

} */

